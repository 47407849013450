// utils
import { DateTime, Duration } from 'luxon'
import { isFinite } from 'lodash'

// ---------------------------------------------
// DATE FORMATTING

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function formatDate(dateISO) {
  return DateTime.fromISO(dateISO).toLocaleString(DateTime.DATE_FULL)
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function formatDateCompact(dateISO) {
  return DateTime.fromISO(dateISO).toLocaleString(DateTime.DATE_MED)
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function formatTime(dateISO) {
  return DateTime.fromISO(dateISO).toLocaleString(DateTime.TIME_SIMPLE)
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function formatDateTime(dateISO) {
  return DateTime.fromISO(dateISO).toLocaleString(DateTime.DATETIME_FULL)
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function formatDateTimeCompact(dateISO) {
  return DateTime.fromISO(dateISO).toLocaleString(DateTime.DATETIME_MED)
}

// ---------------------------------------------
// DURATION FORMATTING

/**
 * @param {string} durationISO
 * @returns {string}
 */
export function formatDurationMinute(durationISO) {
  return Duration.fromISO(durationISO).toFormat('mm:ss')
}

/**
 * @param {string} durationISO
 * @returns {string}
 */
export function formatDurationHour(durationISO) {
  return Duration.fromISO(durationISO).toFormat('hh:mm:ss')
}

// ---------------------------------------------
// DATA SIGNATURE

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDateTimeSignature(dateISO) {
  return DateTime.fromISO(dateISO).toISO({
    format: 'basic',
    includeOffset: false
  })
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDateSignature(dateISO) {
  return DateTime.fromISO(dateISO).toISODate({
    format: 'basic'
  })
}

// ---------------------------------------------
// DATE AS A VALUE

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDateISO(dateISO) {
  return DateTime.fromISO(dateISO).toISODate()
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDayStartDateISO(dateISO) {
  return DateTime.fromISO(dateISO).startOf('day').toISO()
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDayEndDateISO(dateISO) {
  return DateTime.fromISO(dateISO).endOf('day').toISO()
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDayStart(dateISO) {
  return DateTime.fromISO(dateISO).startOf('day')
}

/**
 * @param {string} dateISO
 * @returns {string}
 */
export function getDayEnd(dateISO) {
  return DateTime.fromISO(dateISO).endOf('day')
}

// ---------------------------------------------

/**
 * @callback DateTimeNormalizer
 * @param {DateTime} dateTime
 * @return {DateTime}
 */

/**
 * @callback DateTimeCreator
 * @return {DateTime}
 */

/**
 * @callback DateTimeCreatorFromISO
 * @param {string} iso
 * @return {DateTime}
 */

/**
 * @callback DateTimeCreatorFromMillis
 * @param {number} millis
 * @return {DateTime}
 */

/**
 * @callback DateTimeValueToInput
 * @param {number} millis
 * @return {string}
 */

/**
 * @callback DateTimeInputToValue
 * @param {Date} date
 * @return {number|null}
 */

/**
 * @callback DateTimeValueFormatter
 * @param {number} millis
 * @param {boolean} isLazy - if true, return '...' instead of '-' when millis is not valid number
 * @return {string}
 */

/**
 * @typedef {Object} DateTimeHelper
 * @property {DateTimeInputToValue} normalizeStartAt
 * @property {DateTimeInputToValue} normalizeEndAt
 * @property {DateTimeValueFormatter} userFormatDateTime
 * @property {DateTimeNormalizer} normalize
 * @property {DateTimeCreator} now
 */

// ---------------------------------------------

/**
 * @param {number} millis
 * @param {boolean} isLazy
 * @return {string}
 */
function __formatterFallback(isLazy = false) {
  return isLazy ? '...' : '-'
}

/**
 * @param {Date} date
 * @return {number|null}
 */
function __getISODateOnly(date) {
  const yyyy = `${date.getFullYear()}`.padStart(4, '0')
  const mm = `${date.getMonth() + 1}`.padStart(2, '0')
  const dd = `${date.getDate()}`.padStart(2, '0')

  return `${yyyy}-${mm}-${dd}`
}

/**
 * @param {import('luxon').DateTimeOptions} constructorOpts
 * @param {DateTimeNormalizer} normalizeFn
 * @return {DateTimeHelper}
 */
function createDateTimeHelper(construtorOpts, normalizeFn) {
  /**
   * Normalize DateTime to use helper's configuration
   * @type {DateTimeNormalizer}
   */
  const normalize = (dateTime) => {
    return normalizeFn(dateTime)
  }

  // ---------------------------------------------

  /**
   * @type {DateTimeCreator}
   */
  const now = () => {
    return normalize(DateTime.now())
  }

  /**
   * @type {DateTimeCreatorFromISO}
   */
  const fromISO = (iso) => {
    const result = DateTime.fromISO(iso, construtorOpts)

    if (!result.isValid) return null

    return result
  }

  /**
   * @type {DateTimeCreatorFromMillis}
   */
  const fromMillis = (millis) => {
    if (!isFinite(millis)) return null

    return DateTime.fromMillis(millis, construtorOpts)
  }

  // ---------------------------------------------

  /**
   * @type {DateTimeValueFormatter}
   */
  const userFormatDateTime = (isoString, isLazy) => {
    if (!isoString) return __formatterFallback(isLazy)

    return DateTime.fromISO(isoString, construtorOpts).toLocaleString(
      DateTime.DATETIME_FULL
    )
  }

  return {
    normalize,
    //
    now,
    fromISO,
    fromMillis,
    //
    userFormatDateTime
  }
}

const userDateTime = createDateTimeHelper(
  {
    locale: 'id-ID'
  },
  (dateTime) => dateTime.setLocale('id-ID')
)

export { userDateTime }
