// react
import { forwardRef } from 'react'
// @mui
import { Typography, Link } from '@mui/material'
// utils
import GetFontValue from '../libs/getFontValue'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.asLink
 * @param {string} [props.variant='body1']
 * @param {number} [props.line=2]
 * @param {boolean} [props.persistent=false]
 * @param {React.ReactNode} props.children
 * @param {Object} props.sx
 * @returns {JSX.Element}
 */
const TextMaxLine = forwardRef(
  (
    {
      asLink,
      variant = 'body1',
      line = 2,
      persistent = false,
      children,
      sx,
      ...other
    },
    ref
  ) => {
    const { lineHeight } = GetFontValue(variant)

    const style = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: line,
      WebkitBoxOrient: 'vertical',
      ...(persistent && {
        height: lineHeight * line
      }),
      ...sx
    }

    if (asLink) {
      return (
        <Link
          color='inherit'
          ref={ref}
          variant={variant}
          sx={{ ...style }}
          {...other}
        >
          {children}
        </Link>
      )
    }

    return (
      <Typography
        ref={ref}
        variant={variant}
        sx={{ ...style }}
        {...other}
      >
        {children}
      </Typography>
    )
  }
)

TextMaxLine.displayName = 'TextMaxLine'

export default TextMaxLine
