// @mui
import { Box } from '@mui/material'
import { IMAGE_CDN_URL } from '~/config'

// ----------------------------------------------------------------------

const PNG_EXT_REGEX = /\.png$/i

const BASE_SX = {
  fontSize: 'small',
  color: 'text.secondary',
  backgroundImage:
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOcOG+1LQAFuwIZ+/8pcwAAAABJRU5ErkJggg==)'
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.src
 * @param {string} props.alt
 * @param {string} props.loading
 * @returns {JSX.Element}
 */
export default function NativeImage({
  src,
  alt,
  loading = 'lazy',
  sx,
  ...other
}) {
  const isCDNImage =
    src &&
    !(
      src.startsWith('http://') ||
      src.startsWith('https://') ||
      src.startsWith('data:')
    )

  const fallbackSRC = isCDNImage ? `${IMAGE_CDN_URL}/${src}` : src

  return (
    <Box component='picture'>
      {isCDNImage && (
        <>
          <source
            srcSet={`${IMAGE_CDN_URL}/${src.replace(PNG_EXT_REGEX, '.webp')}`}
            type='image/webp'
          />
          <source
            srcSet={`${IMAGE_CDN_URL}/${src.replace(PNG_EXT_REGEX, '.webp')}`}
            type='image/avif'
          />
          <source
            srcSet={`${IMAGE_CDN_URL}/${src}`}
            type='image/png'
          />
        </>
      )}
      <Box
        component='img'
        src={fallbackSRC}
        alt={alt}
        loading={loading}
        sx={{ ...BASE_SX, ...(sx ?? {}) }}
        {...other}
      />
    </Box>
  )
}

// ----------------------------------------------------------------------
